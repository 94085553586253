import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default function Setup() {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const loaded = ref(false);
  const validToken = ref(false);
  const Token = route.params.token;

  const submit = async (data = {}) => {
    data.Token = Token;
    await store
    .dispatch("resetPasswordConfirm", data)
    .then(() => {
      router.push({
        name: "Accueil",
      });
    })
  };

  onMounted(() => {
    store.dispatch("validateToken", Token.value)
    .then((res) => {
      validToken.value = res.data;
      loaded.value = true;
    })
  });

  return {
    validToken,
    loaded,
    submit
  }
}